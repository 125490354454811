import { API_URL, BASE_URL } from "../constant";
import { PrintedInvoiceListResponse } from "../interfaces/printedinvoice";

class PrintedInvoiceService {
  public static async getList(
    params?: string
  ): Promise<PrintedInvoiceListResponse> {
    const cookiesUser: string = "nathania";
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.printedInvoiceList}${params}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser,
        },
      }
    );

    const response: PrintedInvoiceListResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default PrintedInvoiceService;
