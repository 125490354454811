import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Button } from "react-bootstrap";
import SignIn from "./container/SignIn";
import Invoice from "./container/Invoice";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrintedInvoice from "./container/PrintedInvoice";
import Receipt from "./container/Receipt";
import { getCookie } from "./utils/cookies";
import PreviewPage from "./container/PreviewPage";
import PreviewPageLong from "./container/PreviewPageLong";
import PreviewPaymentPage from "./container/PreviewPaymentPage";
import ReceiptPreviewPage from "./container/ReceiptPreviewPage";
import ReceiptPreviewPageLong from "./container/ReceiptPreviewPageLong";
import ReceiptPreviewPaymentPage from "./container/ReceiptPreviewPaymentPage";

const App = () => {
  const cookies = getCookie("user");
  return (
    <Router>
      <Routes>
        <Route
          path={"/sign-in"}
          element={cookies !== null ? <Navigate to="/invoice" /> : <SignIn />}
        />
        <Route
          path={"/invoice"}
          element={cookies ? <Invoice /> : <Navigate to="/sign-in" />}
        />
        <Route
          path={"/printed-invoice"}
          element={cookies ? <PrintedInvoice /> : <Navigate to="/sign-in" />}
        />
        <Route
          path={"/receipt"}
          element={cookies ? <Receipt /> : <Navigate to="/sign-in" />}
        />
        <Route
          path={"/preview-page/:printType/:previewId"}
          element={cookies ? <PreviewPage /> : <Navigate to="/sign-in" />}
        />
        <Route
          path={"/preview-page-long/:printType/:previewId"}
          element={cookies ? <PreviewPageLong /> : <Navigate to="/sign-in" />}
        />
        <Route
          path={"/preview-payment-page/:printType/:previewId"}
          element={
            cookies ? <PreviewPaymentPage /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path={"/receipt-preview/:printType/:previewId"}
          element={
            cookies ? <ReceiptPreviewPage /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path={"/receipt-preview-long/:printType/:previewId"}
          element={
            cookies ? <ReceiptPreviewPageLong /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path={"/receipt-preview-payment/:printType/:previewId"}
          element={
            cookies ? <ReceiptPreviewPaymentPage /> : <Navigate to="/sign-in" />
          }
        />
        <Route path="/" element={<Navigate to="/sign-in" />} />
      </Routes>
    </Router>
  );
};

export default App;
