import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import {
  InvoiceAction,
  InvoiceSetCurrentPageNumber,
  InvoiceSetFilter,
  InvoiceSetList,
  InvoiceSetLoading,
  InvoiceSetPageNumber,
  InvoiceType,
} from "../types/invoice";

import { InvoiceFilterInterface, InvoiceList } from "../../interfaces/invoice";

interface InvoiceState {
  filter: InvoiceFilterInterface;
  invoiceList: InvoiceList[];
  isLoading: E_FETCH_STATUS;
  totalPage: number;
  currentPage: number;
}

const initialState: InvoiceState = {
  filter: {
    filterInvoiceNumber: "",
    filterTaxInvoiceNumber: "",
    filterCustomerName: "",
    filterItemType: ITEM_TYPE.INITIATE,
    filterPONumber: "",
  },
  invoiceList: [],
  isLoading: E_FETCH_STATUS.INITIATE,
  totalPage: 0,
  currentPage: 1,
};

const {
  INVOICE_SET_FILTER,
  INVOICE_SET_LIST,
  INVOICE_SET_LOADING,
  INVOICE_SET_PAGE_NUMBER,
  INVOICE_SET_CURRENT_PAGE_NUMBER,
} = InvoiceType;

const invoice = (
  state: InvoiceState = initialState,
  action: InvoiceAction
): InvoiceState => {
  const { type } = action;

  const setList = (): InvoiceState => {
    const { payload: invoiceList } = action as InvoiceSetList;
    return {
      ...state,
      invoiceList,
    };
  };

  const setInvoiceLoading = (): InvoiceState => {
    const { payload: isLoading } = action as InvoiceSetLoading;
    return {
      ...state,
      isLoading,
    };
  };

  const setFilter = (): InvoiceState => {
    const { payload: filter } = action as InvoiceSetFilter;
    return {
      ...state,
      filter,
    };
  };

  const setPageNumber = (): InvoiceState => {
    const { payload: totalPage } = action as InvoiceSetPageNumber;
    return {
      ...state,
      totalPage,
    };
  };

  const setCurrentPageNumber = (): InvoiceState => {
    const { payload: currentPage } = action as InvoiceSetCurrentPageNumber;
    return {
      ...state,
      currentPage,
    };
  };

  switch (type) {
    case INVOICE_SET_FILTER:
      return setFilter();
    case INVOICE_SET_LOADING:
      return setInvoiceLoading();
    case INVOICE_SET_LIST:
      return setList();
    case INVOICE_SET_PAGE_NUMBER:
      return setPageNumber();
    case INVOICE_SET_CURRENT_PAGE_NUMBER:
      return setCurrentPageNumber();
    default:
      return state;
  }
};

export default invoice;
