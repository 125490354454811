import { API_URL, BASE_URL } from "../constant";
import { AuthResponse } from "../interfaces/auth";

class AuthService {
  public static async doSignIn(body: FormData): Promise<AuthResponse> {
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.signIn}`, {
      method: "POST",
      body: body,
    });

    const response: AuthResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default AuthService;
