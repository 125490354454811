import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { PreviewList } from "../../interfaces/invoice";
import InvoiceService from "../../services/Invoice";
import "./index.scss";
import concatAddress from "../../utils/concatAddress";
import { ITEM_TYPE, MAX_LENGTH_LONG, MONTH } from "../../constant";
import convertItemTypeFromNumber from "../../utils/convertItemTypeFromNumber";
import logoBlanco from "../../assets/header.png";
import footerBlanco from "../../assets/footer.png";
import thousandSeparator from "../../utils/thousandSeparator";
import convertTerbilang from "../../utils/terbilang";
import ReceiptService from "../../services/Receipt";
import { ReceiptPreviewList } from "../../interfaces/receipt";
import { Helmet } from "react-helmet";
import { getCookie } from "../../utils/cookies";

const cookiesUser = getCookie("user");
const ReceiptPreviewPageLong = () => {
  const { previewId, printType } = useParams();
  const [dataPreview, setDataPreview] = useState<ReceiptPreviewList[]>([]);
  const [customerName, setCustomerName] = useState<string>("");
  const [customerAddress, setCustomerAddress] = useState<string>("");
  const [totalAccumulated, setTotalAccumulated] = useState<number>(0);
  const [receiptNumber, setReceiptNumber] = useState<string>("");
  const [receiptDate, setReceiptDate] = useState<string>("");
  const [printTypeFromAPI, setPrintTypeFromAPI] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");

  const getPreviewList = (): JSX.Element | JSX.Element[] => {
    const tableTemp: JSX.Element | JSX.Element[] = [];
    const length = dataPreview.length;
    const left = MAX_LENGTH_LONG + 1 - length;
    for (let i = 0; i < length; i++) {
      tableTemp.push(
        <tr key={dataPreview[i].receiptNumber}>
          <td>
            <p>{i + 1}</p>
          </td>
          <td>
            <p>{dataPreview[i].invoiceNo}</p>
          </td>
          <td>
            <p>{dataPreview[i].date}</p>
          </td>
          <td>
            <p>{dataPreview[i].taxInvoiceNo}</p>
          </td>
          <td>
            <p>{dataPreview[i].itemType}</p>
          </td>
          <td>
            <div className="display-flex justify-space-between data-total">
              <p>Rp. </p>
              <p>{thousandSeparator(dataPreview[i].total)}</p>
            </div>
          </td>
        </tr>
      );
    }
    for (let i = 0; i < left; i++) {
      tableTemp.push(
        <tr key={`data-kosong${i}`}>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
        </tr>
      );
    }
    return tableTemp;
  };
  const renderPreviewList = (): JSX.Element[] | JSX.Element => {
    let tableBody: JSX.Element[] | JSX.Element;
    tableBody = getPreviewList();
    return tableBody;
  };

  const getReceiptDate = useCallback((receiptDate) => {
    const date = receiptDate.slice(0, 10);
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = MONTH[Number(dateArray[1]) - 1];
    const realDate = dateArray[2];
    return `${realDate} ${month} ${year}`;
  }, []);

  const getCurrentTime = useMemo(() => {
    const date = new Date();
    const codeDate = `0${date.getDate()}`.slice(-2);
    const codeMonth = `0${date.getMonth() + 1}`.slice(-2);
    const codeYear = date.getFullYear();
    const codeHour = `0${date.getHours()}`.slice(-2);
    const codeMinute = `0${date.getMinutes()}`.slice(-2);
    const codeSecond = `0${date.getSeconds()}`.slice(-2);
    return `${codeDate}/${codeMonth}/${codeYear} ${codeHour}:${codeMinute}:${codeSecond}`;
  }, []);

  const printPage = () => {
    window.print();
  };

  useEffect(() => {
    if (Number(printType) >= 1 && Number(printType) <= 5) {
      window.location.href = `/receipt-preview/${printType}/${previewId}`;
    } else if (printType === "7" || printType === "8") {
      window.location.href = `/receipt-preview-payment/${printType}/${previewId}`;
    }
    if (previewId) {
      const body = new FormData();
      body.append("previewId", previewId.toString());
      ReceiptService.getPreview(body).then((data) => {
        if (data.data) {
          let totalTemp = 0;
          const previewTemp: ReceiptPreviewList[] = data.data.map((prev) => {
            totalTemp += prev.total;
            return {
              address: concatAddress(prev.address, prev.address_2),
              date: prev.date,
              invoiceNo: prev.invoice_no,
              itemType: convertItemTypeFromNumber(
                Number(prev.item_type)
              ) as ITEM_TYPE,
              total: prev.total,
              taxInvoiceNo: prev.tax_invoice_no,
              customerName: prev.customer_name,
              poNumber: prev.po_no,
              receiptNumber: prev.generated_for,
              receiptDate: prev.createdAt,
              paymentText: prev.payment_text,
            };
          });
          setReceiptDate(getReceiptDate(previewTemp[0].receiptDate));
          setCustomerName(previewTemp[0].customerName);
          setCustomerAddress(previewTemp[0].address);
          setReceiptNumber(previewTemp[0].receiptNumber);
          setDataPreview(previewTemp);
          setTotalAccumulated(totalTemp);
          setPrintTypeFromAPI(data.data[0].print_type);
          setCreatedBy(data.data[0].createdByName);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (Number(printType) >= 1 && Number(printType) <= 5) {
      window.location.href = `/preview-page/${printType}/${previewId}`;
    } else if (printType === "7" || printType === "8") {
      window.location.href = `/preview-payment-page/${printType}/${previewId}`;
    } else if (
      printType &&
      printTypeFromAPI &&
      printType !== printTypeFromAPI
    ) {
      if (Number(printTypeFromAPI) >= 1 && Number(printTypeFromAPI) <= 5) {
        window.location.href = `/preview-page/${printTypeFromAPI}/${previewId}`;
      } else if (printTypeFromAPI === "7" || printTypeFromAPI === "8") {
        window.location.href = `/preview-payment-page/${printTypeFromAPI}/${previewId}`;
      } else {
        window.location.href = `/preview-page-long/${printTypeFromAPI}/${previewId}`;
      }
    }
  }, [printTypeFromAPI]);

  return (
    <>
      <Helmet>
        <title>{receiptNumber}</title>
      </Helmet>
      <Container fluid id="receiptPreviewLong">
        <div id="nonPrintedArea">
          <div className="display-flex justify-space-between">
            <Button
              variant="danger"
              onClick={() => (window.location.href = "/invoice")}
            >
              Back
            </Button>
            <div>
              <Button variant="primary" onClick={() => printPage()}>
                Print
              </Button>
            </div>
          </div>
          <hr />
        </div>
        <div id="printedArea">
          <Row>
            <Col md={6} sm={6} className="left-content">
              <img src={logoBlanco} className="logo-blanco" />
              <Row>
                <Col md={3} sm={printType === "6" ? 3 : 4}>
                  <p>No. TT</p>
                </Col>
                <Col>
                  <p>: {receiptNumber}</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={printType === "6" ? 3 : 4}>
                  {printType === "9" || printType === "11" ? (
                    <p>Tanggal Email</p>
                  ) : (
                    <p>Tanggal</p>
                  )}
                </Col>
                <Col>
                  <p>: {receiptDate}</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={printType === "6" ? 3 : 4}>
                  <p>Dibuat oleh</p>
                </Col>
                <Col>
                  <p>: {createdBy}</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={printType === "6" ? 3 : 4}>
                  <p>Dicetak oleh</p>
                </Col>
                <Col>
                  <p>: {`${cookiesUser} - ${getCurrentTime}`}</p>
                </Col>
              </Row>
            </Col>
            <Col md={6} sm={6}>
              <Row>
                <p className="title-tanda-terima">TANDA TERIMA</p>
              </Row>
              <Row className="address-to">
                <p>Kepada Yth.</p>
                <p>
                  <b>{customerName}</b>
                </p>
                <p className="customer-address">{customerAddress}</p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Table className="table-preview">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>
                    <p>No.</p>
                  </th>
                  <th>
                    <p>No. Invoice</p>
                  </th>
                  <th>
                    <p>Tgl Invoice</p>
                  </th>
                  <th>
                    <p>No. Faktur Pajak</p>
                  </th>
                  <th>
                    <p>Keterangan</p>
                  </th>
                  <th style={{ width: "20%" }}>
                    <p>Jumlah</p>
                  </th>
                </tr>
              </thead>
              <tbody>{renderPreviewList()}</tbody>
              <tfoot>
                <tr>
                  <td colSpan={4} className="word-total">
                    <p className="terbilang">
                      Terbilang: {convertTerbilang(totalAccumulated)}
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>Total</b>
                    </p>
                  </td>
                  <td>
                    <div className="display-flex justify-space-between data-total">
                      <p>
                        <b>Rp. </b>
                      </p>
                      <p>
                        <b>{thousandSeparator(totalAccumulated)}</b>
                      </p>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Row>
          <Row>
            <Col
              className="no-padding"
              md={printType === "10" || printType === "11" ? 7 : 6}
              sm={printType === "10" || printType === "11" ? 7 : 6}
            >
              <div
                className={`account-info ${
                  (printType === "10" || printType === "11") && "type-kirim"
                }`}
              >
                <p>Informasi Rekening Pembayaran</p>
                <p>BANK BCA - Cabang Samanhudi</p>
                <p>A/C #477-017-7117 a.n CV TRANINDO SEJAHTERA</p>
                <p>
                  Pembayaran dengan Bilyet Giro / Cheque / Transfer akan
                  dianggap sah setelah berhasil dicairkan / masuk ke rekening
                  tsb
                </p>
                {(printType === "10" || printType === "11") && (
                  <>
                    <div className="type-4">
                      <p>
                        <b>
                          Tanda terima harap ditandatangani dan diberi tanggal
                          terima dan diemailkan ke finance.blanco@gmail.com &
                          finance.tranindo@gmail.com
                        </b>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col
              md={printType === "10" || printType === "11" ? 2 : 3}
              sm={printType === "10" || printType === "11" ? 2 : 3}
            />
            <Col md={3} sm={3}>
              <div className="signature-container">
                <div className="signature" />
                <div className="signature-name">
                  <div className="signature-name-start">(</div>
                  <div className="signature-name-filler">
                    .........................................................................................................................................................................................
                  </div>
                  <div className="signature-name-end">)</div>
                </div>
                <div className="signature-desc">
                  <p>TTD / Nama Penerima / Tanggal</p>
                </div>
              </div>
            </Col>
          </Row>
          <div className="footer">
            <img src={footerBlanco} />
          </div>
        </div>
      </Container>
      <style>
        {`
          @media print {
            @page {
              size: 21.5cm 28cm;
              margin: 0 15px;
            }
            #nonPrintedArea {
              display: none;
            }
            .footer {
              position: absolute;
              bottom: 20px;
              left: 0;
              padding: 0 15px;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
        `}
      </style>
    </>
  );
};

export default ReceiptPreviewPageLong;
