import "./index.scss";
import { FaBookOpen } from "react-icons/fa";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { InvoiceList, InvoiceListResponse } from "../../interfaces/invoice";
import thousandSeparator from "../../utils/thousandSeparator";
import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import InvoiceService from "../../services/Invoice";
import convertItemTypeFromNumber from "../../utils/convertItemTypeFromNumber";
import concatAddress from "../../utils/concatAddress";

interface FloatingPageProps {
  invoiceIdList: number[];
}

const FloatingPage: FC<FloatingPageProps> = (props) => {
  const { invoiceIdList } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<E_FETCH_STATUS>(E_FETCH_STATUS.INITIATE);
  const [invoiceList, setInvoiceList] = useState<InvoiceList[]>([]);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getInvoices = async () => {
    const body = new FormData();
    body.append("invoiceIds", invoiceIdList.toString());
    setStatus(E_FETCH_STATUS.FETCHING);
    const res: InvoiceListResponse = await InvoiceService.getInvoicesByIds(
      body
    );
    if (res.data) {
      const invoiceListTemp: InvoiceList[] = res.data.map((invoice) => {
        return {
          id: invoice.id,
          invoiceNumber: invoice.invoice_no,
          customerName: invoice.customer_name,
          taxInvoiceNumber: invoice.tax_invoice_no,
          itemType: convertItemTypeFromNumber(
            Number(invoice.item_type)
          ) as ITEM_TYPE,
          address: concatAddress(invoice.address, invoice.address_2),
          PONumber: invoice.po_no,
          total: invoice.total,
          date: invoice.date,
        };
      });
      setStatus(E_FETCH_STATUS.FETCHED);
      setInvoiceList(invoiceListTemp);
    } else {
      setStatus(E_FETCH_STATUS.ERROR);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getInvoices();
    }
  }, [isModalOpen]);

  const renderCandidateInvoice = () => {
    switch (status) {
      case E_FETCH_STATUS.FETCHING:
        return (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
      case E_FETCH_STATUS.FETCHED:
        return invoiceList.map((invoice, index) => {
          return (
            <tr key={invoice.id}>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td>{invoice.invoiceNumber}</td>
              <td>{invoice.date}</td>
              <td>{invoice.customerName}</td>
              <td className="text-right">{thousandSeparator(invoice.total)}</td>
              <td>{invoice.PONumber}</td>
              <td>{invoice.itemType}</td>
              <td className="font-10">{invoice.address}</td>
              <td>{invoice.taxInvoiceNumber}</td>
            </tr>
          );
        });
      case E_FETCH_STATUS.ERROR:
        return (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={9}
            >
              Error
            </td>
          </tr>
        );
      default:
        return (
          <tr>
            <td>abc</td>
          </tr>
        );
    }
  };
  return (
    <>
      <div className="containerButton" onClick={() => setIsModalOpen(true)}>
        <FaBookOpen size={30} />
      </div>
      <Modal show={isModalOpen} onHide={closeModal} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Checked Invoice List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="table-preview">
            <thead>
              <tr>
                <th
                  style={{
                    width: "3%",
                  }}
                >
                  No.
                </th>
                <th
                  style={{
                    width: "10%",
                  }}
                >
                  Invoice No.
                </th>
                <th
                  style={{
                    width: "8%",
                  }}
                >
                  Date
                </th>
                <th>Customer Name</th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Total
                </th>
                <th>PO No.</th>
                <th>Item Type</th>
                <th
                  style={{
                    width: "20%",
                  }}
                >
                  Address
                </th>
                <th
                  style={{
                    width: "13%",
                  }}
                >
                  Tax Invoice No.
                </th>
              </tr>
            </thead>
            <tbody>
              {renderCandidateInvoice()}
              {/* {invoiceList.map((invoice, index) => {
                return (
                  <tr key={invoice.id}>
                    <td>{index + 1}</td>
                    <td>{invoice.invoiceNumber}</td>
                    <td>{invoice.date}</td>
                    <td>{invoice.customerName}</td>
                    <td>{thousandSeparator(invoice.total)}</td>
                    <td>{invoice.PONumber}</td>
                    <td>{invoice.itemType}</td>
                    <td>{invoice.address}</td>
                    <td>{invoice.taxInvoiceNumber}</td>
                  </tr>
                );
              })} */}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FloatingPage;
