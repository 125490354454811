import { PRINT_TYPE } from "../constant";

const convertPrintTypeIntoString = (printType: PRINT_TYPE) => {
  switch (printType) {
    case PRINT_TYPE.TYPE_1:
      return "1 - TRA";
    case PRINT_TYPE.TYPE_2:
      return "2 - Email";
    case PRINT_TYPE.TYPE_3:
      return "3 - Lunas";
    case PRINT_TYPE.TYPE_4:
      return "4 - Kirim";
    case PRINT_TYPE.TYPE_5:
      return "5 - Kirim + Email";
    case PRINT_TYPE.TYPE_6:
      return "6 - TRA Long";
    case PRINT_TYPE.TYPE_7:
      return "7 - Lunas Banyak";
    case PRINT_TYPE.TYPE_8:
      return "8 - Custom Lunas Banyak";
    case PRINT_TYPE.TYPE_9:
      return "9 - TRA Long Email";
    case PRINT_TYPE.TYPE_10:
      return "10 - TL Kirim";
    case PRINT_TYPE.TYPE_11:
      return "11 - TL Kirim + Email";
  }
};

export default convertPrintTypeIntoString;
