import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import HeaderMain from "../../component/HeaderMain";
import FilterInvoice from "./FilterInovice";

import {
  invoiceCurrentPageSelector,
  invoiceListSelector,
  invoiceLoadingSelector,
  invoiceTotalPageSelector,
} from "../../redux/selectors/invoice";

import {
  FaPlus,
  FaTrashAlt,
  FaFileExport,
  FaFileExcel,
  FaClone,
} from "react-icons/fa";
import {
  E_FETCH_STATUS,
  MAX_LENGTH_LONG,
  MAX_LENGTH_NORMAL,
  PRINT_TYPE,
} from "../../constant";
import thousandSeparator from "../../utils/thousandSeparator";
import {
  loadInvoiceList,
  setCurrentPage,
  setInvoiceLoading,
} from "../../redux/actions/invoice";
import InvoiceService from "../../services/Invoice";
import FloatingPage from "../../component/FloatingPage";
import { InvoiceDataOdooResponse, InvoiceList } from "../../interfaces/invoice";
import convertPrintTypeToNumber from "../../utils/convertPrintTypeToNumber";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Invoice = () => {
  const navigate = useNavigate();
  const isLoading = useSelector(invoiceLoadingSelector);
  const invoiceList = useSelector(invoiceListSelector);
  const pageNumber = useSelector(invoiceTotalPageSelector);
  const currentPage = useSelector(invoiceCurrentPageSelector);
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileValue, setFileValue] = useState<string>("");
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
  const [isModalDeleteShowing, setIsModalDeleteShowing] =
    useState<boolean>(false);
  const [checkedInvoice, setCheckedInvoice] = useState<number[]>([]);
  const [checkAllInvoice, setCheckAllInvoice] = useState<boolean>(false);
  const [disableCheckBox, setDisableCheckBox] = useState<boolean>(false);
  const [printType, setPrintType] = useState<PRINT_TYPE>(PRINT_TYPE.TYPE_0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInvoiceList());
  }, []);

  const closeModal = () => {
    setIsModalShowing(false);
  };

  const closeModalDelete = () => {
    setIsModalDeleteShowing(false);
  };

  const onFileChange = (event: any) => {
    event.preventDefault();
    // const reader = new FileReader();
    if (fileRef.current) {
      setFileValue(fileRef.current.value);
    }
  };

  const triggerFileExplorer = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const uploadFile = () => {
    const body = new FormData();
    if (fileRef.current && fileRef.current.files) {
      body.append("fileInvoice", fileRef.current.files[0]);
      InvoiceService.uploadInvoice(body).then((data) => {
        if (data.status === 1) {
          alert("Data has been uploaded successfully");
          window.location.reload();
        }
      });
    }
  };

  const onChangePrintType = (e: any) => {
    const { value } = e.target;
    setPrintType(value as PRINT_TYPE);
    setCheckedInvoice([]);
    setCheckAllInvoice(false);
    setDisableCheckBox(false);
  };

  const onCheckAllInvoice = () => {
    if (checkedInvoice.length >= 1) {
      setCheckedInvoice([]);
    } else {
      let max = 0;
      switch (printType) {
        case PRINT_TYPE.TYPE_6:
        case PRINT_TYPE.TYPE_9:
        case PRINT_TYPE.TYPE_10:
        case PRINT_TYPE.TYPE_11:
          max = MAX_LENGTH_LONG;
          break;
        case PRINT_TYPE.DELETE:
          max = MAX_LENGTH_LONG;
          break;
        default:
          max = MAX_LENGTH_NORMAL;
          break;
      }
      let arrTemp: number[] = [...checkedInvoice];
      for (let i = 0; i < invoiceList.length; i++) {
        if (arrTemp.length < max) {
          arrTemp.push(invoiceList[i].id);
        } else {
          break;
        }
      }
      setCheckedInvoice(arrTemp);
    }
  };

  const onChangeCheckedInvoice = (invoice: InvoiceList) => {
    let max = 0;
    switch (printType) {
      case PRINT_TYPE.TYPE_6:
      case PRINT_TYPE.TYPE_9:
      case PRINT_TYPE.TYPE_10:
      case PRINT_TYPE.TYPE_11:
        max = MAX_LENGTH_LONG;
        break;
      case PRINT_TYPE.DELETE:
        max = MAX_LENGTH_LONG;
        break;
      default:
        max = MAX_LENGTH_NORMAL;
        break;
    }

    let arrTemp: number[] = [...checkedInvoice];
    if (arrTemp.includes(invoice.id)) {
      let indexToBeRemoved = arrTemp.indexOf(invoice.id);
      if (indexToBeRemoved > -1) {
        arrTemp.splice(indexToBeRemoved, 1);
      }
    } else {
      arrTemp.push(invoice.id);
    }
    if (arrTemp.length >= max) {
      setDisableCheckBox(true);
      setCheckAllInvoice(true);
    } else {
      setDisableCheckBox(false);
      setCheckAllInvoice(false);
    }
    setCheckedInvoice(arrTemp);
  };

  const createReceipt = () => {
    const body = new FormData();
    body.append("invoiceId", checkedInvoice.toString());
    body.append("printType", convertPrintTypeToNumber(printType).toString());
    InvoiceService.createPreview(body).then((data) => {
      if (data) {
        const previewId = data.data;
        if (
          convertPrintTypeToNumber(printType) >= 1 &&
          convertPrintTypeToNumber(printType) <= 5
        ) {
          navigate(
            `/preview-page/${convertPrintTypeToNumber(printType)}/${previewId}`
          );
        } else if (
          convertPrintTypeToNumber(printType) === 6 ||
          convertPrintTypeToNumber(printType) === 9 ||
          convertPrintTypeToNumber(printType) === 10 ||
          convertPrintTypeToNumber(printType) === 11
        ) {
          navigate(
            `/preview-page-long/${convertPrintTypeToNumber(
              printType
            )}/${previewId}`
          );
        } else {
          navigate(
            `/preview-payment-page/${convertPrintTypeToNumber(
              printType
            )}/${previewId}`
          );
        }
      }
    });
  };

  const getDataFromOdoo = async () => {
    // const test = await dispatch(getOdooData());
    // console.log(test);
    // alert("Data has been fetched sucessfully");
    // await dispatch(loadInvoiceList());
    dispatch(setInvoiceLoading(E_FETCH_STATUS.FETCHING));
    const res: InvoiceDataOdooResponse = await InvoiceService.fetchOdooData();
    if (res.status === 1) {
      dispatch(setInvoiceLoading(E_FETCH_STATUS.FETCHED));
      alert("Data has been fetched successfully from Odoo");
    } else {
      dispatch(setInvoiceLoading(E_FETCH_STATUS.ERROR));
      if (res.data) {
        alert(
          `Data with id ${res.data} failed to be fetched \nPlease contact the programmer`
        );
      } else {
        alert(res.message);
      }
    }
    dispatch(loadInvoiceList());
  };

  const deleteInvoices = () => {
    const body = new FormData();
    body.append("invoiceIds", checkedInvoice.toString());
    InvoiceService.deleteInvoice(body).then((data) => {
      if (data.status === 1) {
        alert("Successfully delete selected invoices");
        dispatch(loadInvoiceList());
        setIsModalDeleteShowing(false);
      } else {
        alert("Failed to delete selected invoices");
      }
    });
  };

  const getTableInvoice = () => {
    return invoiceList.map((invoice) => {
      return (
        <tr key={invoice.id}>
          <td
            style={{
              textAlign: "center",
            }}
          >
            <Form.Check
              type={"checkbox"}
              disabled={
                printType === PRINT_TYPE.ERROR ||
                printType === PRINT_TYPE.TYPE_0 ||
                (disableCheckBox && !checkedInvoice.includes(invoice.id))
              }
              checked={checkedInvoice.includes(invoice.id)}
              onChange={() => onChangeCheckedInvoice(invoice)}
            />
          </td>
          <td>{invoice.invoiceNumber}</td>
          <td>{invoice.date}</td>
          <td>{invoice.customerName}</td>
          <td className="text-right">{thousandSeparator(invoice.total)}</td>
          <td className="font-14">{invoice.PONumber}</td>
          <td>{invoice.itemType}</td>
          <td className="font-10">{invoice.address}</td>
          <td>{invoice.taxInvoiceNumber}</td>
        </tr>
      );
    });
  };

  useEffect(() => {
    let max = 0;
    switch (printType) {
      case PRINT_TYPE.TYPE_6:
      case PRINT_TYPE.TYPE_9:
      case PRINT_TYPE.TYPE_10:
      case PRINT_TYPE.TYPE_11:
        max = MAX_LENGTH_LONG;
        break;
      case PRINT_TYPE.DELETE:
        max = MAX_LENGTH_LONG;
        break;
      default:
        max = MAX_LENGTH_NORMAL;
        break;
    }
    if (checkedInvoice.length >= max) {
      setCheckAllInvoice(true);
      setDisableCheckBox(true);
    } else {
      setCheckAllInvoice(false);
      setDisableCheckBox(false);
    }
  }, [checkedInvoice.length]);

  const renderTableInvoiceList = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (isLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          invoiceList.length > 0 ? (
            getTableInvoice()
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center" }}>
                No invoice found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={9}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              No invoice found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th
              style={{
                textAlign: "center",
              }}
            >
              <Form.Check
                type={"checkbox"}
                disabled={
                  printType === PRINT_TYPE.ERROR ||
                  printType === PRINT_TYPE.TYPE_0
                }
                onChange={onCheckAllInvoice}
                checked={checkAllInvoice}
              />
            </th>
            <th
              style={{
                width: "10%",
              }}
            >
              Invoice No.
            </th>
            <th
              style={{
                width: "8%",
              }}
            >
              Date
            </th>
            <th>Customer Name</th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Total
            </th>
            <th>PO No.</th>
            <th>Item Type</th>
            <th
              style={{
                width: "15%",
              }}
            >
              Address
            </th>
            <th
              style={{
                width: "12%",
              }}
            >
              Tax Invoice No.
            </th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  return (
    <>
      <HeaderMain></HeaderMain>
      <br />
      <div className="custom-container">
        <Row>
          <Col md={12}>
            <h3>Invoice List</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <div className="display-flex align-items-center">
              <p style={{ paddingLeft: 0 }}>Select Print Type</p>
              <Form>
                <Form.Select
                  aria-label="Default select example"
                  value={printType}
                  onChange={(e) => onChangePrintType(e)}
                >
                  <option value={PRINT_TYPE.TYPE_0}>Select Print Type</option>
                  <option value={PRINT_TYPE.TYPE_1}>Print Type 1 - TRA</option>
                  <option value={PRINT_TYPE.TYPE_2}>
                    Print Type 2 - Email
                  </option>
                  <option value={PRINT_TYPE.TYPE_3}>
                    Print Type 3 - Lunas
                  </option>
                  <option value={PRINT_TYPE.TYPE_4}>
                    Print Type 4 - Kirim
                  </option>
                  <option value={PRINT_TYPE.TYPE_5}>
                    Print Type 5 - Kirim + Email
                  </option>
                  <option value={PRINT_TYPE.TYPE_6}>
                    Print Type 6 - TRA Long
                  </option>
                  <option value={PRINT_TYPE.TYPE_7}>
                    Print Type 7 - Lunas Banyak
                  </option>
                  <option value={PRINT_TYPE.TYPE_8}>
                    Print Type 8 - Custom Lunas Banyak
                  </option>
                  <option value={PRINT_TYPE.TYPE_9}>
                    Print Type 9 - TRA Long Email
                  </option>
                  <option value={PRINT_TYPE.TYPE_10}>
                    Print Type 10 - TL Kirim
                  </option>
                  <option value={PRINT_TYPE.TYPE_11}>
                    Print Type 11 - TL Kirim + Email
                  </option>
                  <option value={PRINT_TYPE.DELETE}>Delete Invoices</option>
                </Form.Select>
              </Form>
            </div>
          </Col>
          <Col
            md={7}
            className="display-flex align-items-center justify-content-end btn-action"
          >
            <Button variant="primary" onClick={() => getDataFromOdoo()}>
              <FaClone />
              Fetch Data From Odoo
            </Button>
            <Button variant="primary" onClick={() => setIsModalShowing(true)}>
              <FaPlus />
              Import New Voice
            </Button>
            {printType === PRINT_TYPE.DELETE && (
              <Button
                variant="danger"
                onClick={() => setIsModalDeleteShowing(true)}
              >
                <FaTrashAlt />
                Delete Invoice
              </Button>
            )}
            {printType !== PRINT_TYPE.DELETE && (
              <Button
                variant="primary"
                disabled={checkedInvoice.length === 0}
                onClick={createReceipt}
              >
                <FaFileExport />
                Create New Receipt
              </Button>
            )}
          </Col>
        </Row>
        <FilterInvoice />
        <br />
        {isLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setCurrentPage(1));
                    dispatch(loadInvoiceList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setCurrentPage(currentPage - 1));
                    dispatch(loadInvoiceList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setCurrentPage(currentPage + 1));
                    dispatch(loadInvoiceList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setCurrentPage(pageNumber));
                    dispatch(loadInvoiceList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
        <Row>
          {/* <Spinner animation="border" /> */}
          {renderTableInvoiceList()}
        </Row>
        {isLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setCurrentPage(1));
                    dispatch(loadInvoiceList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setCurrentPage(currentPage - 1));
                    dispatch(loadInvoiceList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setCurrentPage(currentPage + 1));
                    dispatch(loadInvoiceList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setCurrentPage(pageNumber));
                    dispatch(loadInvoiceList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </div>
      <Modal show={isModalShowing} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Import Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="primary"
            onClick={triggerFileExplorer}
            className="width-100 display-flex align-items-center justify-center"
          >
            <FaFileExcel />
            Upload File
          </Button>
          <input
            type="file"
            style={{ display: "none" }}
            accept="application/vnd.ms-excel"
            onChange={onFileChange}
            ref={fileRef}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={uploadFile}
            disabled={fileValue === ""}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isModalDeleteShowing} onHide={closeModalDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all of the invoices that you have
          checked?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteInvoices}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {checkedInvoice.length !== 0 && (
        <FloatingPage invoiceIdList={checkedInvoice} />
      )}
    </>
  );
};

export default Invoice;
