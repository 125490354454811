import React, { ChangeEvent, FormEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ITEM_TYPE } from "../../../constant";
import { InvoiceFilterInterface } from "../../../interfaces/invoice";
import {
  loadInvoiceList,
  setCurrentPage,
  setInvoiceFilter,
  setInvoiceList,
} from "../../../redux/actions/invoice";
const FilterInvoice = () => {
  const dispatch = useDispatch();
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState<string>("");
  const [filterTaxInvoiceNumber, setFilterTaxInvoiceNumber] =
    useState<string>("");
  const [filterCustomerName, setFilterCustomerName] = useState<string>("");
  const [filterItemType, setFilterItemType] = useState<ITEM_TYPE>(
    ITEM_TYPE.INITIATE
  );
  const [filterPONumber, setFilterPONumber] = useState<string>("");

  const changeFilterInvoiceNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterInvoiceNumber(value);
  };

  const changeFilterTaxInvoiceNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterTaxInvoiceNumber(value);
  };

  const changeFilterCustomerName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterCustomerName(value);
  };

  const changeFilterPONumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterPONumber(value);
  };

  const changeFilterItemType = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterItemType(value as ITEM_TYPE);
  };

  const applyFilter = () => {
    const filterTemp: InvoiceFilterInterface = {
      filterInvoiceNumber,
      filterTaxInvoiceNumber,
      filterCustomerName,
      filterItemType,
      filterPONumber,
    };
    dispatch(setCurrentPage(1));
    dispatch(setInvoiceFilter(filterTemp));
    dispatch(loadInvoiceList());
  };

  const resetFilter = () => {
    setFilterInvoiceNumber("");
    setFilterTaxInvoiceNumber("");
    setFilterCustomerName("");
    setFilterItemType(ITEM_TYPE.INITIATE);
    setFilterPONumber("");
    const filterTemp: InvoiceFilterInterface = {
      filterInvoiceNumber: "",
      filterTaxInvoiceNumber: "",
      filterCustomerName: "",
      filterItemType: ITEM_TYPE.INITIATE,
      filterPONumber: "",
    };
    dispatch(setCurrentPage(1));
    dispatch(setInvoiceFilter(filterTemp));
    dispatch(loadInvoiceList());
  };

  return (
    <>
      <br />
      <Form autoComplete="off">
        <Row>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Invoice Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Invoice Number"
                value={filterInvoiceNumber}
                onChange={(e) => changeFilterInvoiceNumber(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Tax Invoice No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tax Invoice Number"
                value={filterTaxInvoiceNumber}
                onChange={(e) => changeFilterTaxInvoiceNumber(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Customer Name"
                value={filterCustomerName}
                onChange={(e) => changeFilterCustomerName(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Item Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={filterItemType}
                onChange={(e) => changeFilterItemType(e)}
              >
                <option value={ITEM_TYPE.INITIATE}>All</option>
                <option value={ITEM_TYPE.BLANCO}>Blanco</option>
                <option value={ITEM_TYPE.HANSGROHE}>HANSGROHE</option>
                <option value={ITEM_TYPE.CGS}>CGS</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>PO Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="PO Number"
                value={filterPONumber}
                onChange={(e) => changeFilterPONumber(e)}
              />
            </Form.Group>
          </Col>
          <Col
            md={2}
            className="display-flex align-items-end btn-action justify-content-end"
          >
            <Button variant="outline-primary" onClick={resetFilter}>
              Reset
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterInvoice;
