import React, { FC, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AuthService from "../../services/Auth";
import { setCookie } from "../../utils/cookies";
import "./index.css";
import { DivContent } from "./style";

const SignIn: FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const changeUserName = (e: any) => {
    setUserName(e.target.value);
  };

  const changePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const signIn = () => {
    const body = new FormData();
    body.append("email", userName);
    body.append("password", password);
    AuthService.doSignIn(body)
      .then((user) => {
        if (user.data) {
          setCookie("user", user.data.email, 1 / 8);
          window.location.href = "/invoice";
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <>
      <DivContent style={{ height: "100vh" }}>
        <Row className="justify-content-md-center">
          <Col className="text-center primaryColor">
            <h3>
              CV Tranindo Sejahtera
              <br />
              Receipt Maker
            </h3>
          </Col>
          <Form className="form-sign-in">
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                value={userName}
                onChange={(e) => changeUserName(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => changePassword(e)}
              />
            </Form.Group>
            <div className="d-grid gap-2">
              <Button
                size="lg"
                className="btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  signIn();
                }}
                type="submit"
              >
                Login
              </Button>
            </div>
          </Form>
        </Row>
      </DivContent>
    </>
  );
};

export default SignIn;
