export enum ITEM_TYPE {
  INITIATE = "INITIATE",
  BLANCO = "BLANCO",
  HANSGROHE = "HANSGROHE",
  CGS = "CGS",
}

export enum PRINT_TYPE {
  TYPE_0 = "TYPE_0",
  TYPE_1 = "TYPE_1",
  TYPE_2 = "TYPE_2",
  TYPE_3 = "TYPE_3",
  TYPE_4 = "TYPE_4",
  TYPE_5 = "TYPE_5",
  TYPE_6 = "TYPE_6",
  TYPE_7 = "TYPE_7",
  TYPE_8 = "TYPE_8",
  TYPE_9 = "TYPE_9",
  TYPE_10 = "TYPE_10",
  TYPE_11 = "TYPE_11",
  DELETE = "DELETE",
  ERROR = "ERROR",
}

export enum E_FETCH_STATUS {
  INITIATE = 0,
  FETCHING = 1,
  FETCHED = 2,
  ERROR = 3,
}

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost/blanco_be/"
    : "https://invoice.tranindosejahtera.com/blanco_be/";

// export const BASE_URL = "https://invoice.tranindosejahtera.com/blanco_be/";

export const API_URL = {
  invoiceList: "get-invoice-list",
  uploadInvoice: "upload-file",
  printedInvoiceList: "get-printed-invoice-list",
  receiptList: "get-receipt",
  signIn: "sign-in",
  createPreview: "create-preview",
  getPreview: "get-preview",
  getLatestReceiptId: "get-latest-receipt-id",
  getReceiptPreview: "get-print",
  createReceipt: "create-receipt",
  deleteInvoicees: "delete-invoice",
  invoiceListByIds: "get-invoice-by-id",
  fetchOdooData: "fetch-odoo-data",
};

export const MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MAX_LENGTH_LONG = 35;
export const MAX_LENGTH_NORMAL = 8;
