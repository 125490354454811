import React, { ChangeEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ReceiptFilterInterface } from "../../../interfaces/receipt";
import {
  loadReceiptList,
  setReceiptCurrentPage,
  setReceiptFilter,
} from "../../../redux/actions/receipt";

const FilterReceipt = () => {
  const dispatch = useDispatch();
  const [filterReceiptNumber, setFilterReceiptNumber] = useState<string>("");
  const [filterCreatedAt, setFilterCreatedAt] = useState<string>("");

  const applyFilter = () => {
    const filterTemp: ReceiptFilterInterface = {
      filterReceiptNumber,
      filterCreatedAt,
    };
    dispatch(setReceiptCurrentPage(1));
    dispatch(setReceiptFilter(filterTemp));
    dispatch(loadReceiptList());
  };

  const resetFilter = () => {
    setFilterCreatedAt("");
    setFilterReceiptNumber("");
    const filterTemp: ReceiptFilterInterface = {
      filterReceiptNumber: "",
      filterCreatedAt: "",
    };
    dispatch(setReceiptCurrentPage(1));
    dispatch(setReceiptFilter(filterTemp));
    dispatch(loadReceiptList());
  };

  const changeFilterReceiptNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    console.log(value);
    setFilterReceiptNumber(value);
  };

  const changeFilterCreatedAt = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    console.log(value);
    setFilterCreatedAt(value);
  };
  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Receipt Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Receipt Number"
                value={filterReceiptNumber}
                onChange={(e) => changeFilterReceiptNumber(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Created At</Form.Label>
              <Form.Control
                type="date"
                value={filterCreatedAt}
                onChange={(e) => changeFilterCreatedAt(e)}
              />
            </Form.Group>
          </Col>
          <Col
            md={8}
            className="display-flex align-items-center justify-content-end btn-action-vertical"
          >
            <Button variant="outline-primary" onClick={resetFilter}>
              Reset
            </Button>
            &nbsp;
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterReceipt;
