import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";

import "./index.css";

const NavBarMain = () => {
  return (
    <Nav variant="tabs">
      <Nav.Item className="nav-item">
        <Nav.Link
          href="/invoice"
          active={
            window.location.pathname.indexOf("invoice") > 0 &&
            window.location.pathname.indexOf("printed") < 0
          }
        >
          Invoice
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className="nav-item">
        <Nav.Link
          href="/printed-invoice"
          active={window.location.pathname.indexOf("printed-invoice") > 0}
        >
          Printed Invoice
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className="nav-item">
        <Nav.Link
          href="/receipt"
          active={window.location.pathname.indexOf("receipt") > 0}
        >
          Receipt
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavBarMain;
