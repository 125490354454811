import { combineReducers } from "redux";
import invoice from "./invoice";
import printedinvoice from "./printedinvoice";
import receipt from "./receipt";

export const reducers = combineReducers({
  invoice,
  printedinvoice,
  receipt,
});

export type AppStateType = ReturnType<typeof reducers>;
