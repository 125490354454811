import { ITEM_TYPE } from "../constant";

const convertItemTypeFromNumber = (itemNumber: number) => {
  switch (itemNumber) {
    case 1:
      return ITEM_TYPE.BLANCO;
    case 2:
      return ITEM_TYPE.HANSGROHE;
    case 3:
      return ITEM_TYPE.CGS;
    default:
      return " ";
  }
};

export default convertItemTypeFromNumber;
