import React, { ChangeEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { PrintedInvoiceFilterInterface } from "../../../interfaces/printedinvoice";
import {
  loadPrintedInvoiceList,
  setPrintedInvoiceCurrentPage,
  setPrintedInvoiceFilter,
} from "../../../redux/actions/printedinvoice";

const FilterPrintedInvoice = () => {
  const dispatch = useDispatch();
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState<string>("");
  const [filterInvoiceDate, setFilterInvoiceDate] = useState<string>("");
  const [filterCustomerName, setFilterCustomerName] = useState<string>("");
  const [filterReceiptNumber, setFilterReceiptNumber] = useState<string>("");
  const [filterReceiptDate, setFilterReceiptDate] = useState<string>("");
  const [filterPONumber, setFilterPONumber] = useState<string>("");

  const resetFilter = () => {
    setFilterInvoiceNumber("");
    setFilterInvoiceDate("");
    setFilterCustomerName("");
    setFilterReceiptNumber("");
    setFilterReceiptDate("");
    setFilterPONumber("");
    const filterTemp: PrintedInvoiceFilterInterface = {
      filterInvoiceNumber: "",
      filterInvoiceDate: "",
      filterCustomerName: "",
      filterReceiptNumber: "",
      filterReceiptDate: "",
      filterPONumber: "",
    };
    dispatch(setPrintedInvoiceCurrentPage(1));
    dispatch(setPrintedInvoiceFilter(filterTemp));
    dispatch(loadPrintedInvoiceList());
  };

  const applyFilter = () => {
    const filterTemp: PrintedInvoiceFilterInterface = {
      filterInvoiceNumber,
      filterInvoiceDate,
      filterCustomerName,
      filterReceiptNumber,
      filterReceiptDate,
      filterPONumber,
    };
    dispatch(setPrintedInvoiceCurrentPage(1));
    dispatch(setPrintedInvoiceFilter(filterTemp));
    dispatch(loadPrintedInvoiceList());
  };

  const changeFilterInvoiceNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterInvoiceNumber(value);
  };

  const changeFilterInvoiceDate = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterInvoiceDate(value);
  };

  const changeFilterCustomerName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterCustomerName(value);
  };

  const changeFilterReceiptNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterReceiptNumber(value);
  };

  const changeFilterReceiptDate = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterReceiptDate(value);
  };

  const changeFilterPONumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterPONumber(value);
  };

  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Invoice Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Invoice Number"
                value={filterInvoiceNumber}
                onChange={(e) => changeFilterInvoiceNumber(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Invoice Date</Form.Label>
              <Form.Control
                type="date"
                value={filterInvoiceDate}
                onChange={(e) => changeFilterInvoiceDate(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Customer Name"
                value={filterCustomerName}
                onChange={(e) => changeFilterCustomerName(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Receipt Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Receipt Number"
                value={filterReceiptNumber}
                onChange={(e) => changeFilterReceiptNumber(e)}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Receipt Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Receipt Date"
                value={filterReceiptDate}
                onChange={(e) => changeFilterReceiptDate(e)}
              />
            </Form.Group>
          </Col>
          <Col md={1}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>PO Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="PO Number"
                value={filterPONumber}
                onChange={(e) => changeFilterPONumber(e)}
              />
            </Form.Group>
          </Col>
          <Col
            md={1}
            className="display-flex align-items-center btn-action-vertical justify-content-end flex-direction-column"
          >
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>
            <Button variant="outline-primary" onClick={resetFilter}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterPrintedInvoice;
