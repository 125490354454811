import { AppStateType } from "../reducers";

export const invoiceFilterSelector = (state: AppStateType) => {
  return state.invoice.filter;
};

export const invoiceLoadingSelector = (state: AppStateType) => {
  return state.invoice.isLoading;
};

export const invoiceListSelector = (state: AppStateType) => {
  return state.invoice.invoiceList;
};

export const invoiceTotalPageSelector = (state: AppStateType) => {
  return state.invoice.totalPage;
};

export const invoiceCurrentPageSelector = (state: AppStateType) => {
  return state.invoice.currentPage;
};
