import { API_URL, BASE_URL } from "../constant";
import {
  CreatePreviewResponse,
  DeleteInvoicesResponse,
  InvoiceDataOdooResponse,
  InvoiceListResponse,
  LatestReceiptIdResponse,
  PreviewResponse,
} from "../interfaces/invoice";
import { getCookie } from "../utils/cookies";

class InvoiceService {
  public static async getList(params?: string): Promise<InvoiceListResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.invoiceList}${params ? params : ""}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: InvoiceListResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async uploadInvoice(params: FormData) {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.uploadInvoice}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body: params,
    });
    const response = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
  }

  public static async createPreview(
    params: FormData
  ): Promise<CreatePreviewResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createPreview}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body: params,
    });
    const response: CreatePreviewResponse = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getPreview(body: FormData): Promise<PreviewResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.getPreview}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });
    const response: PreviewResponse = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getLatestReceiptId(): Promise<LatestReceiptIdResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getLatestReceiptId}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );
    const response: LatestReceiptIdResponse = await fetchResponse.json();
    if (response.data) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getInvoicesByIds(
    body: FormData
  ): Promise<InvoiceListResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.invoiceListByIds}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: InvoiceListResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteInvoice(
    body: FormData
  ): Promise<DeleteInvoicesResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.deleteInvoicees}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });
    const response: DeleteInvoicesResponse = await fetchResponse.json();
    if (response.status) return response;
    throw new Error("Abnormal Response");
  }

  public static async fetchOdooData(): Promise<InvoiceDataOdooResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.fetchOdooData}`, {
      method: "GET",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
    });
    const response: InvoiceDataOdooResponse = await fetchResponse.json();
    if (response) return response;
    throw new Error("Abnormal Response");
  }
}

export default InvoiceService;
