import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewList } from "../../interfaces/invoice";
import InvoiceService from "../../services/Invoice";
import "./index.scss";
import concatAddress from "../../utils/concatAddress";
import { ITEM_TYPE, MONTH } from "../../constant";
import convertItemTypeFromNumber from "../../utils/convertItemTypeFromNumber";
import logoBlanco from "../../assets/header.png";
import footerBlanco from "../../assets/footer.png";
import thousandSeparator from "../../utils/thousandSeparator";
import convertTerbilang from "../../utils/terbilang";
import { Helmet } from "react-helmet";
import ReceiptService from "../../services/Receipt";
import { getEventListeners } from "events";
import { getCookie } from "../../utils/cookies";

const cookiesUser = getCookie("user");
const PreviewPaymentPage = () => {
  const { previewId, printType } = useParams();
  const [dataPreview, setDataPreview] = useState<PreviewList[]>([]);
  const [customerName, setCustomerName] = useState<string>("");
  const [customerAddress, setCustomerAddress] = useState<string>("");
  const [totalAccumulated, setTotalAccumulated] = useState<number>(0);
  const [newTTNumber, setNewTTNumber] = useState<string>("");
  const [paymentEditing, setPaymentEditing] = useState<boolean>(false);
  const [isModalEditShowing, setIsModalEditShowing] = useState<boolean>(false);
  const [printTypeFromAPI, setPrintTypeFromAPI] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");

  //For Form
  const [pembayaranText, setPembayaranText] = useState<string[]>([]);
  const [customNameTextEdit, setCustomNameTextEdit] = useState<string>("");
  const [customNameText, setCustomNameText] = useState<string>("");
  const [customAddressTextEdit, setCustomAddressTextEdit] =
    useState<string>("");
  const [customAddressText, setCustomAddressText] = useState<string>("");

  const generateTTNumber = useCallback((latestId: string) => {
    // Previous receipt
    let latestNumberId = Number(latestId.slice(-4));
    const previousMonthLatestNumberId = Number(latestId.substring(6, 8));
    // Current Receipt
    const date = new Date();
    const codeYear = date.getFullYear().toString().slice(-2);
    const codeMonth = `0${date.getMonth() + 1}`.slice(-2);
    // If previous is not the same, re initiate the code id
    if (previousMonthLatestNumberId !== Number(date.getMonth() + 1)) {
      latestNumberId = 0;
    }
    const codeId = `000${latestNumberId + 1}`.slice(-4);
    const result = `TT-${codeYear}-${codeMonth}-${codeId}`;
    return result;
  }, []);

  const formatTodayDate = useMemo(() => {
    const date = new Date();
    const codeDate = `0${date.getDate()}`.slice(-2);
    const codeMonth = MONTH[date.getMonth()];
    const codeYear = date.getFullYear();
    return `${codeDate} ${codeMonth} ${codeYear}`;
  }, []);

  const getCurrentTime = useMemo(() => {
    const date = new Date();
    const codeDate = `0${date.getDate()}`.slice(-2);
    const codeMonth = `0${date.getMonth() + 1}`.slice(-2);
    const codeYear = date.getFullYear();
    const codeHour = `0${date.getHours()}`.slice(-2);
    const codeMinute = `0${date.getMinutes()}`.slice(-2);
    const codeSecond = `0${date.getSeconds()}`.slice(-2);
    return `${codeDate}/${codeMonth}/${codeYear} ${codeHour}:${codeMinute}:${codeSecond}`;
  }, []);

  const confirmEdit = () => {
    setPaymentEditing(false);
  };

  const closeModal = () => {
    setCustomNameTextEdit("");
    setCustomAddressTextEdit("");
    setIsModalEditShowing(false);
  };

  const changePembayaranText = (e: ChangeEvent, index: number) => {
    const { value } = e.target as HTMLInputElement;
    const pembayaranTextEditTemp = [...pembayaranText];
    pembayaranTextEditTemp[index] = value;
    setPembayaranText(pembayaranTextEditTemp);
  };

  const changeCustomNameText = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setCustomNameTextEdit(value);
  };

  const changeCustomAddressText = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setCustomAddressTextEdit(value);
  };

  const confirmEditCustomerInfo = () => {
    setCustomNameText(customNameTextEdit);
    setCustomAddressText(customAddressTextEdit);
    setCustomNameTextEdit("");
    setCustomAddressTextEdit("");
    setIsModalEditShowing(false);
  };

  const getPreviewList = (): JSX.Element | JSX.Element[] => {
    const tableTemp: JSX.Element | JSX.Element[] = [];
    const length = dataPreview.length;
    const left = 8 - length;
    for (let i = 0; i < length; i++) {
      tableTemp.push(
        <tr key={dataPreview[i].id}>
          <td>
            <p>{i + 1}</p>
          </td>
          <td>
            <p>{dataPreview[i].invoiceNo}</p>
          </td>
          <td>
            <p>{dataPreview[i].date}</p>
          </td>
          <td>
            <p>{dataPreview[i].taxInvoiceNo}</p>
          </td>
          <td>
            <p>{dataPreview[i].itemType}</p>
          </td>
          <td>
            {paymentEditing ? (
              <Form.Control
                type="text"
                placeholder="Pembayaran"
                className="custom-input-payment"
                value={pembayaranText[i]}
                onChange={(e) => changePembayaranText(e, i)}
              />
            ) : (
              <p>{pembayaranText[i]}</p>
            )}
          </td>
          <td>
            <div className="display-flex justify-space-between data-total">
              <p>Rp. </p>
              <p>{thousandSeparator(dataPreview[i].total)}</p>
            </div>
          </td>
        </tr>
      );
    }
    for (let i = 0; i < left; i++) {
      tableTemp.push(
        <tr key={`data-kosong${i}`}>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
          <td>
            <p>&nbsp;</p>
          </td>
        </tr>
      );
    }
    return tableTemp;
  };
  const renderPreviewList = (): JSX.Element[] | JSX.Element => {
    let tableBody: JSX.Element[] | JSX.Element;
    tableBody = getPreviewList();
    return tableBody;
  };

  const printPage = () => {
    // checkBeforePrint();
    for (let i = 0; i < dataPreview.length; i++) {
      if (pembayaranText[i] === "" || !pembayaranText[i]) {
        alert(
          `WARNING!!!\nPEMBAYARAN TEXT UNTUK INVOICE ${dataPreview[i].invoiceNo} BELUM DIISI!`
        );
        return false;
      }
    }
    window.print();
    createReceipt();
  };

  const checkBeforePrint = () => {
    for (let i = 0; i < dataPreview.length; i++) {
      if (pembayaranText[i] === "" || !pembayaranText[i]) {
        alert(
          `WARNING!!!\nPEMBAYARAN TEXT UNTUK INVOICE ${dataPreview[i].invoiceNo} BELUM DIISI!`
        );
        return false;
      }
    }
  };

  const createReceipt = () => {
    const body = new FormData();
    const invoiceIds = dataPreview.map((prev) => prev.id);
    if (previewId) body.append("previewId", previewId);
    body.append("invoiceIds", invoiceIds.toString());
    body.append(
      "customerName",
      printType === "8" ? customNameText : customerName
    );
    body.append(
      "customerAddress",
      printType === "8" ? customAddressText : customerAddress
    );
    body.append("total", totalAccumulated.toString());
    body.append("receiptNo", newTTNumber);
    body.append("paymentText", pembayaranText.toString());
    if (printType) body.append("printType", printType);

    ReceiptService.createReceipt(body).then((data) => {
      if (data.status !== 0) {
        alert("Receipt succesfully created");
        window.location.href = "/invoice";
      } else {
        alert(
          "There's an error while trying to create the receipt. Please contact the administrator!"
        );
      }
    });
  };

  const getReceiptId = async () => {
    const res = await InvoiceService.getLatestReceiptId();
    if (res.data) {
      setNewTTNumber(generateTTNumber(res.data.receipt_no));
    }
  };

  const getPreviewData = async () => {
    if (previewId) {
      const body = new FormData();
      body.append("previewId", previewId.toString());
      const res = await InvoiceService.getPreview(body);
      if (res.data) {
        let totalTemp = 0;
        const previewTemp: PreviewList[] = res.data.map((prev) => {
          totalTemp += prev.total;
          return {
            id: prev.id,
            address: concatAddress(prev.address, prev.address_2),
            date: prev.date,
            invoiceNo: prev.invoice_no,
            itemType: convertItemTypeFromNumber(
              Number(prev.item_type)
            ) as ITEM_TYPE,
            total: prev.total,
            taxInvoiceNo: prev.tax_invoice_no,
            customerName: prev.customer_name,
          };
        });
        setPrintTypeFromAPI(res.data[0].print_type);
        setCustomerName(previewTemp[0].customerName);
        setCustomerAddress(previewTemp[0].address);
        setDataPreview(previewTemp);
        setTotalAccumulated(totalTemp);
        setCreatedBy(res.data[0].createdByName);
      }
    }
  };

  useEffect(() => {
    getReceiptId().then(() => {
      getPreviewData();
    });
  }, []);

  const checkPrintTypeMatch = () => {
    if (Number(printType) >= 1 && Number(printType) <= 5) {
      window.location.href = `/preview-page/${printType}/${previewId}`;
    } else if (printType === "6") {
      window.location.href = `/preview-page-long/${printType}/${previewId}`;
    } else if (
      printType &&
      printTypeFromAPI &&
      printType !== printTypeFromAPI
    ) {
      if (Number(printTypeFromAPI) >= 1 && Number(printTypeFromAPI) <= 5) {
        window.location.href = `/preview-page/${printTypeFromAPI}/${previewId}`;
      } else if (printTypeFromAPI === "6") {
        window.location.href = `/preview-page-long/${printTypeFromAPI}/${previewId}`;
      } else {
        window.location.href = `/preview-payment-page/${printTypeFromAPI}/${previewId}`;
        return false;
      }
    }
  };

  useEffect(() => {
    checkPrintTypeMatch();
    window.addEventListener("beforeprint", checkBeforePrint);
    window.addEventListener("afterprint", createReceipt);
    return () => {
      window.removeEventListener("beforeprint", checkBeforePrint);
      window.removeEventListener("afterprint", createReceipt);
    };
  }, [
    dataPreview,
    totalAccumulated,
    customerName,
    customerAddress,
    printTypeFromAPI,
    pembayaranText,
  ]);

  return (
    <>
      <Helmet>
        <title>{newTTNumber}</title>
      </Helmet>
      <Container fluid id="printPreviewPayment">
        <div id="nonPrintedArea">
          <div className="display-flex justify-space-between">
            <Button
              variant="danger"
              onClick={() => (window.location.href = "/invoice")}
            >
              Back
            </Button>
            <div>
              {printType === "8" && (
                <Button
                  variant="primary"
                  onClick={() => setIsModalEditShowing(true)}
                >
                  Edit Customer Info
                </Button>
              )}
              &nbsp;
              {paymentEditing ? (
                <Button variant="primary" onClick={() => confirmEdit()}>
                  Save
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => setPaymentEditing(true)}
                >
                  Edit Pembayaran
                </Button>
              )}
              &nbsp;
              {!paymentEditing && (
                <Button variant="primary" onClick={() => printPage()}>
                  Print
                </Button>
              )}
            </div>
          </div>
          <hr />
        </div>
        <div id="printedArea">
          <Row>
            <Col md={6} sm={6} className="left-content">
              <img src={logoBlanco} className="logo-blanco" />
              <Row>
                <Col md={3} sm={3}>
                  <p>No. TT</p>
                </Col>
                <Col>
                  <p>: {newTTNumber}</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={3}>
                  <p>Tanggal</p>
                </Col>
                <Col>
                  <p>: {formatTodayDate}</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={3}>
                  <p>Dibuat oleh</p>
                </Col>
                <Col>
                  <p>: {createdBy}</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={3}>
                  <p>Dicetak oleh</p>
                </Col>
                <Col>
                  <p>: {`${cookiesUser} - ${getCurrentTime}`}</p>
                </Col>
              </Row>
            </Col>
            <Col md={6} sm={6}>
              <Row>
                <p className="title-tanda-terima">TANDA TERIMA (Nota Lunas)</p>
              </Row>
              <Row className="address-to">
                <p>Kepada Yth.</p>
                <p>
                  <b>{printType === "8" ? customNameText : customerName}</b>
                </p>
                <p className="customer-address">
                  {printType === "8" ? customAddressText : customerAddress}
                </p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Table className="table-preview">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>
                    <p>No.</p>
                  </th>
                  <th>
                    <p>No. Invoice</p>
                  </th>
                  <th>
                    <p>Tgl Invoice</p>
                  </th>
                  <th>
                    <p>No. Faktur Pajak</p>
                  </th>
                  <th>
                    <p>Keterangan</p>
                  </th>
                  <th style={{ width: "20%" }}>
                    <p>Pembayaran</p>
                  </th>
                  <th style={{ width: "15%" }}>
                    <p>Jumlah</p>
                  </th>
                </tr>
              </thead>
              <tbody>{renderPreviewList()}</tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} className="word-total">
                    <p className="terbilang">
                      Terbilang: {convertTerbilang(totalAccumulated)}
                    </p>
                  </td>
                  <td>
                    <p>
                      <b>Total</b>
                    </p>
                  </td>
                  <td>
                    <div className="display-flex justify-space-between data-total">
                      <p>
                        <b>Rp. </b>
                      </p>
                      <p>
                        <b>{thousandSeparator(totalAccumulated)}</b>
                      </p>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Row>
          <Row>
            <Col className="no-padding" md={7} sm={7}>
              <div className="account-info">
                <div className="type-4">
                  <p>
                    <b>
                      Tanda terima harap ditandatangani dan diberi tanggal
                      terima dan diemailkan ke finance.blanco@gmail.com &
                      finance.tranindo@gmail.com
                    </b>
                  </p>
                </div>
              </div>
            </Col>
            <Col md={2} sm={2} />
            <Col md={3} sm={3}>
              <div className="signature-container">
                <div className="signature" />
                <div className="signature-name">
                  <div className="signature-name-start">(</div>
                  <div className="signature-name-filler">
                    .........................................................................................................................................................................................
                  </div>
                  <div className="signature-name-end">)</div>
                </div>
                <div className="signature-desc">
                  <p>TTD / Nama Penerima / Tanggal</p>
                </div>
              </div>
            </Col>
          </Row>
          <div className="footer">
            <img src={footerBlanco} />
          </div>
        </div>
      </Container>
      <Modal show={isModalEditShowing} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Pembayaran</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Customer Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Customer Name"
            value={customNameTextEdit}
            onChange={(e) => changeCustomNameText(e)}
          />
          <Form.Label>Customer Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Customer Address"
            value={customAddressTextEdit}
            onChange={(e) => changeCustomAddressText(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={confirmEditCustomerInfo}>
            Confirm Edit
          </Button>
        </Modal.Footer>
      </Modal>
      <style>
        {`
          @media print {
            @page {
              size: 21.5cm 14cm;
              margin: 0 15px;
            }
            #nonPrintedArea {
              display: none;
            }
            .footer {
              position: absolute;
              bottom: 20px;
              left: 0;
              padding: 0 15px;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
        `}
      </style>
    </>
  );
};

export default PreviewPaymentPage;
