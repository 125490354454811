const concatAddress = (address: string | null, address2?: string | null) => {
  if (address) {
    if (address2) {
      return `${address} ${address2}`;
    }
    return address;
  }
  return "-";
};

export default concatAddress;
