import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { eraseCookie, getCookie } from "../../utils/cookies";
import NavBarMain from "../NavBarMain";
import logoMain from "../../assets/header.png";
import "./index.scss";

const HeaderMain = () => {
  const cookiesUser = getCookie("user");
  const signOut = () => {
    eraseCookie("user");
    window.location.href = "/sign-in";
  };
  return (
    <>
      <Container fluid className="border-bottom">
        <Row className="align-items-center">
          <Col md={2}>
            <img src={logoMain} className="logo-header" />
          </Col>
          <Col md={10} className="username-info">
            <p>{cookiesUser}</p>
            <p className="sign-out-btn" onClick={signOut}>
              Sign Out
            </p>
          </Col>
        </Row>
        <Row>
          <NavBarMain />
        </Row>
      </Container>
    </>
  );
};

export default HeaderMain;
