import { AppStateType } from "../reducers";

export const printedInvoiceFilterSelector = (state: AppStateType) => {
  return state.printedinvoice.filter;
};

export const printedInvoiceListSelector = (state: AppStateType) => {
  return state.printedinvoice.printedInvoiceList;
};

export const printedInvoiceLoadingSelector = (state: AppStateType) => {
  return state.printedinvoice.isLoading;
};

export const printedInvoiceCurrentPageSelector = (state: AppStateType) => {
  return state.printedinvoice.currentPage;
};

export const printedInvoiceTotalPageSelector = (state: AppStateType) => {
  return state.printedinvoice.totalPage;
};
