import { PRINT_TYPE } from "../constant";

const convertPrintTypeToNumber = (printType: PRINT_TYPE) => {
  switch (printType) {
    case PRINT_TYPE.TYPE_1:
      return 1;
    case PRINT_TYPE.TYPE_2:
      return 2;
    case PRINT_TYPE.TYPE_3:
      return 3;
    case PRINT_TYPE.TYPE_4:
      return 4;
    case PRINT_TYPE.TYPE_5:
      return 5;
    case PRINT_TYPE.TYPE_6:
      return 6;
    case PRINT_TYPE.TYPE_7:
      return 7;
    case PRINT_TYPE.TYPE_8:
      return 8;
    case PRINT_TYPE.TYPE_9:
      return 9;
    case PRINT_TYPE.TYPE_10:
      return 10;
    case PRINT_TYPE.TYPE_11:
      return 11;
    default:
      return 0;
  }
};

export default convertPrintTypeToNumber;
