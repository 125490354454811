import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Pagination,
  Spinner,
  Button,
} from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import HeaderMain from "../../component/HeaderMain";
import { E_FETCH_STATUS, PRINT_TYPE } from "../../constant";
import {
  loadReceiptList,
  setReceiptCurrentPage,
} from "../../redux/actions/receipt";
import {
  receiptCurrentPageSelector,
  receiptListSelector,
  receiptLoadingSelector,
  receiptTotalPageSelector,
} from "../../redux/selectors/receipt";
import convertPrintTypeIntoString from "../../utils/convertPrintTypeIntoString";
import convertPrintTypeToNumber from "../../utils/convertPrintTypeToNumber";
import thousandSeparator from "../../utils/thousandSeparator";
import FilterReceipt from "./FilterReceipt";

const Receipt = () => {
  const receiptList = useSelector(receiptListSelector);
  const isLoading = useSelector(receiptLoadingSelector);
  const currentPage = useSelector(receiptCurrentPageSelector);
  const pageNumber = useSelector(receiptTotalPageSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadReceiptList());
  }, []);

  const printReceipt = (printType: PRINT_TYPE, previewId: number) => {
    const type = convertPrintTypeToNumber(printType);
    if (type >= 1 && type <= 5) {
      window.location.href = `/receipt-preview/${type}/${previewId}`;
    } else if (type === 6 || type === 9 || type === 10 || type === 11) {
      window.location.href = `/receipt-preview-long/${type}/${previewId}`;
    } else {
      window.location.href = `/receipt-preview-payment/${type}/${previewId}`;
    }
  };

  const getTableReceipt = () => {
    return receiptList.map((receipt) => {
      return (
        <tr key={receipt.id}>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {receipt.receiptNumber}
          </td>
          <td className="text-right">{thousandSeparator(receipt.total)}</td>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {receipt.createdAt}
          </td>
          <td>{convertPrintTypeIntoString(receipt.printType)}</td>
          <td>
            <div className="display-flex align-items-center">
              <Button
                variant="primary"
                className="w-100"
                onClick={() =>
                  printReceipt(receipt.printType, receipt.previewId)
                }
              >
                <FaPrint /> Print
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderReceiptList = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (isLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          receiptList.length > 0 ? (
            getTableReceipt()
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center" }}>
                No Receipt found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={9}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              No Receipt found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Receipt No.
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Total
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Date Created
            </th>
            <th>Print Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  return (
    <>
      <HeaderMain />
      <br />
      <div className="custom-container">
        <Row>
          <Col md={12}>
            <h3>Receipt</h3>
            <hr />
          </Col>
        </Row>
        <FilterReceipt />
        <br />
        {isLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(1));
                    dispatch(loadReceiptList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(currentPage - 1));
                    dispatch(loadReceiptList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(currentPage + 1));
                    dispatch(loadReceiptList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(pageNumber));
                    dispatch(loadReceiptList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
        <Row>{renderReceiptList()}</Row>
        {isLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(1));
                    dispatch(loadReceiptList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(currentPage - 1));
                    dispatch(loadReceiptList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(currentPage + 1));
                    dispatch(loadReceiptList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === pageNumber}
                  onClick={() => {
                    dispatch(setReceiptCurrentPage(pageNumber));
                    dispatch(loadReceiptList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Receipt;
