import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import { ReceiptList } from "../../interfaces/receipt";

export enum ReceiptType {
  RECEIPT_SET_LIST = "RECEIPT_SET_LIST",
  RECEIPT_SET_FILTER = "RECEIPT_SET_FILTER",
  RECEIPT_SET_LOADING = "RECEIPT_SET_LOADING",
  RECEIPT_SET_PAGE_NUMBER = "RECEIPT_SET_PAGE_NUMBER",
  RECEIPT_SET_CURRENT_PAGE = "RECEIPT_SET_CURRENT_PAGE",
}

export interface ReceiptSetList {
  type: ReceiptType.RECEIPT_SET_LIST;
  payload: ReceiptList[];
}

export interface ReceiptSetLoading {
  type: ReceiptType.RECEIPT_SET_LOADING;
  payload: E_FETCH_STATUS;
}

export interface ReceiptSetPageNumber {
  type: ReceiptType.RECEIPT_SET_PAGE_NUMBER;
  payload: number;
}

export interface ReceiptSetCurrentPageNumber {
  type: ReceiptType.RECEIPT_SET_CURRENT_PAGE;
  payload: number;
}

export interface ReceiptSetFilter {
  type: ReceiptType.RECEIPT_SET_FILTER;
  payload: {
    filterReceiptNumber: string;
    filterCreatedAt: string;
  };
}

export type ReceiptAction =
  | ReceiptSetList
  | ReceiptSetFilter
  | ReceiptSetCurrentPageNumber
  | ReceiptSetLoading
  | ReceiptSetPageNumber;
