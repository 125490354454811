import { AppStateType } from "../reducers";

export const receiptFilterSelector = (state: AppStateType) => {
  return state.receipt.filter;
};

export const receiptLoadingSelector = (state: AppStateType) => {
  return state.receipt.isLoading;
};

export const receiptCurrentPageSelector = (state: AppStateType) => {
  return state.receipt.currentPage;
};

export const receiptTotalPageSelector = (state: AppStateType) => {
  return state.receipt.totalPage;
};

export const receiptListSelector = (state: AppStateType) => {
  return state.receipt.receiptList;
};
