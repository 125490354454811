import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import { PrintedInvoiceList } from "../../interfaces/printedinvoice";

export enum PrintedInvoiceType {
  PRINTED_INVOICE_SET_LIST = "PRINTED_INVOICE_SET_LIST",
  PRINTED_INVOICE_SET_FILTER = "PRINTED_INVOICE_SET_FILTER",
  PRINTED_INVOICE_SET_LOADING = "PRINTED_INVOICE_SET_LOADING",
  PRINTED_INVOICE_SET_TOTAL_PAGE = "PRINTED_INVOICE_SET_TOTAL_PAGE",
  PRINTED_INVOICE_SET_CURRENT_PAGE = "PRINTED_INVOICE_SET_CURRENT_PAGE",
}

export interface PrintedInvoiceSetList {
  type: PrintedInvoiceType.PRINTED_INVOICE_SET_LIST;
  payload: PrintedInvoiceList[];
}

export interface PrintedInvoiceSetLoading {
  type: PrintedInvoiceType.PRINTED_INVOICE_SET_LOADING;
  payload: E_FETCH_STATUS;
}

export interface PrintedInvoiceSetTotalPage {
  type: PrintedInvoiceType.PRINTED_INVOICE_SET_TOTAL_PAGE;
  payload: number;
}

export interface PrintedInvoiceSetCurrentPage {
  type: PrintedInvoiceType.PRINTED_INVOICE_SET_CURRENT_PAGE;
  payload: number;
}

export interface PrintedInvoiceSetFilter {
  type: PrintedInvoiceType.PRINTED_INVOICE_SET_FILTER;
  payload: {
    filterInvoiceNumber: string;
    filterInvoiceDate: string;
    filterCustomerName: string;
    filterReceiptNumber: string;
    filterReceiptDate: string;
    filterPONumber: string;
  };
}

export type PrintedInvoiceAction =
  | PrintedInvoiceSetList
  | PrintedInvoiceSetFilter
  | PrintedInvoiceSetCurrentPage
  | PrintedInvoiceSetLoading
  | PrintedInvoiceSetTotalPage;
