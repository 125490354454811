import { API_URL, BASE_URL } from "../constant";
import {
  ReceiptListResponse,
  ReceiptPreviewResponse,
} from "../interfaces/receipt";
import { getCookie } from "../utils/cookies";

class ReceiptService {
  public static async getList(params?: string): Promise<ReceiptListResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.receiptList}${params}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ReceiptListResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.data) {
      return response;
    }
    throw new Error("Abnormal response");
  }
  public static async getPreview(
    body: FormData
  ): Promise<ReceiptPreviewResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.getPreview}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });
    const response: ReceiptPreviewResponse = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
  public static async getReceiptPreview(
    body: FormData
  ): Promise<ReceiptPreviewResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getReceiptPreview}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );
    const response: ReceiptPreviewResponse = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createReceipt(body: FormData) {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createReceipt}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });
    const response = await fetchResponse.json();
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default ReceiptService;
