import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import HeaderMain from "../../component/HeaderMain";
import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import { PrintedInvoiceList } from "../../interfaces/printedinvoice";
import {
  loadPrintedInvoiceList,
  setPrintedInvoiceCurrentPage,
} from "../../redux/actions/printedinvoice";
import {
  printedInvoiceCurrentPageSelector,
  printedInvoiceFilterSelector,
  printedInvoiceListSelector,
  printedInvoiceLoadingSelector,
  printedInvoiceTotalPageSelector,
} from "../../redux/selectors/printedinvoice";
import PrintedInvoiceService from "../../services/PrintedInvoice";
import convertItemTypeFromNumber from "../../utils/convertItemTypeFromNumber";
import thousandSeparator from "../../utils/thousandSeparator";
import FilterPrintedInvoice from "./FilterPrintedInvoice";

const PrintedInvoice = () => {
  const dispatch = useDispatch();
  const printedInvoiceList = useSelector(printedInvoiceListSelector);
  const isLoading = useSelector(printedInvoiceLoadingSelector);
  const currentPage = useSelector(printedInvoiceCurrentPageSelector);
  const totalPage = useSelector(printedInvoiceTotalPageSelector);

  useEffect(() => {
    dispatch(loadPrintedInvoiceList());
  }, []);

  const getPrintedInvoiceList = () => {
    return printedInvoiceList.map((invoice) => {
      return (
        <tr>
          <td>{invoice.invoiceNumber}</td>
          <td>{invoice.invoiceDate}</td>
          <td>{invoice.taxInvoiceNumber}</td>
          <td>{invoice.customerName}</td>
          <td>{invoice.PONumber}</td>
          <td>{invoice.itemType}</td>
          <td className="text-right">{thousandSeparator(invoice.total)}</td>
          <td>{invoice.receiptNumber}</td>
          <td>{invoice.receiptDate}</td>
        </tr>
      );
    });
  };

  const renderPrintedInvoiceList = () => {
    let tableBody: JSX.Element | JSX.Element[];
    switch (isLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          printedInvoiceList.length > 0 ? (
            getPrintedInvoiceList()
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center" }}>
                No invoice found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={9}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              No invoice found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Invoice No.</th>
            <th>Invoice Date</th>
            <th>Tax Invoice No.</th>
            <th>Customer Name</th>
            <th>PO Number</th>
            <th>Item Type</th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Total
            </th>
            <th>Receipt No.</th>
            <th>Receipt Date</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };
  return (
    <>
      <HeaderMain />
      <br />
      <div className="custom-container">
        <Row>
          <Col md={12}>
            <h3>Printed Invoice</h3>
            <hr />
          </Col>
        </Row>
        <FilterPrintedInvoice />
        <br />
        {isLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(1));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(currentPage - 1));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(currentPage + 1));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(totalPage));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
        <br />
        <Row>{renderPrintedInvoiceList()}</Row>
        {isLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(1));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(currentPage - 1));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(currentPage + 1));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setPrintedInvoiceCurrentPage(totalPage));
                    dispatch(loadPrintedInvoiceList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default PrintedInvoice;
