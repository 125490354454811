import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import {
  PrintedInvoiceFilterInterface,
  PrintedInvoiceList,
  PrintedInvoiceListResponse,
} from "../../interfaces/printedinvoice";
import PrintedInvoiceService from "../../services/PrintedInvoice";
import convertItemTypeFromNumber from "../../utils/convertItemTypeFromNumber";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import {
  PrintedInvoiceAction,
  PrintedInvoiceType,
} from "../types/printedinvoice";

export const setPrintedInvoiceFilter = (
  filter: PrintedInvoiceFilterInterface
): PrintedInvoiceAction => {
  return {
    type: PrintedInvoiceType.PRINTED_INVOICE_SET_FILTER,
    payload: filter,
  };
};

export const setPrintedInvoiceLoading = (
  status: E_FETCH_STATUS
): PrintedInvoiceAction => {
  return {
    type: PrintedInvoiceType.PRINTED_INVOICE_SET_LOADING,
    payload: status,
  };
};

export const setPrintedInvoiceList = (
  list: PrintedInvoiceList[]
): PrintedInvoiceAction => {
  return {
    type: PrintedInvoiceType.PRINTED_INVOICE_SET_LIST,
    payload: list,
  };
};

export const setPrintedInvoiceCurrentPage = (
  page: number
): PrintedInvoiceAction => {
  return {
    type: PrintedInvoiceType.PRINTED_INVOICE_SET_CURRENT_PAGE,
    payload: page,
  };
};

export const setPrintedInvoiceTotalPage = (
  page: number
): PrintedInvoiceAction => {
  return {
    type: PrintedInvoiceType.PRINTED_INVOICE_SET_TOTAL_PAGE,
    payload: page,
  };
};

export const loadPrintedInvoiceList = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setPrintedInvoiceLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        printedinvoice: { filter, currentPage },
      } = reduxState;

      let params = `?page_number=${currentPage}`;
      if (filter.filterInvoiceNumber !== "") {
        params += `&invoice_no=${filter.filterInvoiceNumber}`;
      }
      if (filter.filterReceiptNumber !== "") {
        params += `&receipt_no=${filter.filterReceiptNumber}`;
      }
      if (filter.filterInvoiceDate !== "") {
        params += `&date=${filter.filterInvoiceDate}`;
      }
      if (filter.filterPONumber !== "") {
        params += `&po_no=${filter.filterPONumber}`;
      }
      if (filter.filterCustomerName !== "") {
        params += `&customer_name=${filter.filterCustomerName}`;
      }
      if (filter.filterReceiptDate !== "") {
        params += `&receipt_date=${filter.filterReceiptDate}`;
      }
      const res: PrintedInvoiceListResponse =
        await PrintedInvoiceService.getList(params);
      if (res.data) {
        const printedInvoiceListTemp: PrintedInvoiceList[] = res.data.map(
          (invoice) => {
            return {
              id: invoice.id,
              invoiceNumber: invoice.invoice_no,
              invoiceDate: invoice.date,
              taxInvoiceNumber: invoice.tax_invoice_no,
              customerName: invoice.customer_name,
              PONumber: invoice.po_no,
              itemType: convertItemTypeFromNumber(
                Number(invoice.item_type)
              ) as ITEM_TYPE,
              total: invoice.total,
              receiptNumber: invoice.generated_for,
              receiptDate: invoice.createdAt,
            };
          }
        );
        dispatch(setPrintedInvoiceList(printedInvoiceListTemp));
        dispatch(setPrintedInvoiceTotalPage(res.total_page));
        dispatch(setPrintedInvoiceLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setPrintedInvoiceLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setPrintedInvoiceLoading(E_FETCH_STATUS.ERROR));
    }
  };
};
