import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import { InvoiceList } from "../../interfaces/invoice";

export enum InvoiceType {
  INVOICE_SET_LIST = "INVOICE_SET_LIST",
  INVOICE_SET_FILTER = "INVOICE_SET_FILTER",
  INVOICE_SET_LOADING = "INVOICE_SET_LOADING",
  INVOICE_SET_PAGE_NUMBER = "INVOICE_SET_PAGE_NUMBER",
  INVOICE_SET_CURRENT_PAGE_NUMBER = "INVOICE_SET_CURRENT_PAGE_NUMBER",
}

export interface InvoiceSetList {
  type: InvoiceType.INVOICE_SET_LIST;
  payload: InvoiceList[];
}

export interface InvoiceSetLoading {
  type: InvoiceType.INVOICE_SET_LOADING;
  payload: E_FETCH_STATUS;
}

export interface InvoiceSetPageNumber {
  type: InvoiceType.INVOICE_SET_PAGE_NUMBER;
  payload: number;
}

export interface InvoiceSetCurrentPageNumber {
  type: InvoiceType.INVOICE_SET_CURRENT_PAGE_NUMBER;
  payload: number;
}

export interface InvoiceSetFilter {
  type: InvoiceType.INVOICE_SET_FILTER;
  payload: {
    filterInvoiceNumber: string;
    filterTaxInvoiceNumber: string;
    filterCustomerName: string;
    filterItemType: ITEM_TYPE;
    filterPONumber: string;
  };
}

export type InvoiceAction =
  | InvoiceSetList
  | InvoiceSetFilter
  | InvoiceSetLoading
  | InvoiceSetPageNumber
  | InvoiceSetCurrentPageNumber;
