import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import {
  PrintedInvoiceFilterInterface,
  PrintedInvoiceList,
} from "../../interfaces/printedinvoice";
import {
  PrintedInvoiceAction,
  PrintedInvoiceSetCurrentPage,
  PrintedInvoiceSetFilter,
  PrintedInvoiceSetList,
  PrintedInvoiceSetLoading,
  PrintedInvoiceSetTotalPage,
  PrintedInvoiceType,
} from "../types/printedinvoice";

interface PrintedInvoiceState {
  filter: PrintedInvoiceFilterInterface;
  isLoading: E_FETCH_STATUS;
  printedInvoiceList: PrintedInvoiceList[];
  currentPage: number;
  totalPage: number;
}

const initialState: PrintedInvoiceState = {
  filter: {
    filterInvoiceNumber: "",
    filterInvoiceDate: "",
    filterCustomerName: "",
    filterReceiptNumber: "",
    filterReceiptDate: "",
    filterPONumber: "",
  },
  isLoading: E_FETCH_STATUS.INITIATE,
  printedInvoiceList: [],
  currentPage: 1,
  totalPage: 0,
};

const {
  PRINTED_INVOICE_SET_FILTER,
  PRINTED_INVOICE_SET_LIST,
  PRINTED_INVOICE_SET_CURRENT_PAGE,
  PRINTED_INVOICE_SET_LOADING,
  PRINTED_INVOICE_SET_TOTAL_PAGE,
} = PrintedInvoiceType;

const printedinvoice = (
  state: PrintedInvoiceState = initialState,
  action: PrintedInvoiceAction
): PrintedInvoiceState => {
  const { type } = action;

  const setList = (): PrintedInvoiceState => {
    const { payload: printedInvoiceList } = action as PrintedInvoiceSetList;
    return {
      ...state,
      printedInvoiceList,
    };
  };

  const setFilter = (): PrintedInvoiceState => {
    const { payload: filter } = action as PrintedInvoiceSetFilter;
    return {
      ...state,
      filter,
    };
  };

  const setCurrentPage = (): PrintedInvoiceState => {
    const { payload: currentPage } = action as PrintedInvoiceSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setLoading = (): PrintedInvoiceState => {
    const { payload: isLoading } = action as PrintedInvoiceSetLoading;
    return {
      ...state,
      isLoading,
    };
  };

  const setTotalPage = (): PrintedInvoiceState => {
    const { payload: totalPage } = action as PrintedInvoiceSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case PRINTED_INVOICE_SET_FILTER:
      return setFilter();
    case PRINTED_INVOICE_SET_LIST:
      return setList();
    case PRINTED_INVOICE_SET_CURRENT_PAGE:
      return setCurrentPage();
    case PRINTED_INVOICE_SET_LOADING:
      return setLoading();
    case PRINTED_INVOICE_SET_TOTAL_PAGE:
      return setTotalPage();
    default:
      return state;
  }
};

export default printedinvoice;
