import { PRINT_TYPE } from "../constant";

const convertPrintTypeFromNumber = (printType: number) => {
  switch (printType) {
    case 1:
      return PRINT_TYPE.TYPE_1;
    case 2:
      return PRINT_TYPE.TYPE_2;
    case 3:
      return PRINT_TYPE.TYPE_3;
    case 4:
      return PRINT_TYPE.TYPE_4;
    case 5:
      return PRINT_TYPE.TYPE_5;
    case 6:
      return PRINT_TYPE.TYPE_6;
    case 7:
      return PRINT_TYPE.TYPE_7;
    case 8:
      return PRINT_TYPE.TYPE_8;
    case 9:
      return PRINT_TYPE.TYPE_9;
    case 10:
      return PRINT_TYPE.TYPE_10;
    case 11:
      return PRINT_TYPE.TYPE_11;
    default:
      return PRINT_TYPE.ERROR;
  }
};

export default convertPrintTypeFromNumber;
