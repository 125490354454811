import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import {
  ReceiptFilterInterface,
  ReceiptList,
  ReceiptListResponse,
} from "../../interfaces/receipt";
import ReceiptService from "../../services/Receipt";
import convertPrintTypeFromNumber from "../../utils/convertPrintTypeFromNumber";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { ReceiptAction, ReceiptType } from "../types/receipt";

export const setReceiptFilter = (
  filter: ReceiptFilterInterface
): ReceiptAction => {
  return {
    type: ReceiptType.RECEIPT_SET_FILTER,
    payload: filter,
  };
};

export const setReceiptList = (receiptList: ReceiptList[]): ReceiptAction => {
  return {
    type: ReceiptType.RECEIPT_SET_LIST,
    payload: receiptList,
  };
};

export const setReceiptTotalPage = (pageNumber: number) => {
  return {
    type: ReceiptType.RECEIPT_SET_PAGE_NUMBER,
    payload: pageNumber,
  };
};

export const setReceiptCurrentPage = (page: number): ReceiptAction => {
  return {
    type: ReceiptType.RECEIPT_SET_CURRENT_PAGE,
    payload: page,
  };
};

export const setReceiptLoading = (status: E_FETCH_STATUS): ReceiptAction => {
  return {
    type: ReceiptType.RECEIPT_SET_LOADING,
    payload: status,
  };
};

export const loadReceiptList = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setReceiptLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        receipt: { filter, currentPage },
      } = reduxState;

      let params = `?page_number=${currentPage}`;
      if (filter.filterReceiptNumber !== "") {
        params += `&receipt_no=${filter.filterReceiptNumber}`;
      }
      if (filter.filterCreatedAt !== "") {
        params += `&createdAt=${filter.filterCreatedAt}`;
      }
      const res: ReceiptListResponse = await ReceiptService.getList(params);
      if (res.data) {
        const receiptListTemp: ReceiptList[] = res.data.map((receipt) => {
          return {
            id: receipt.id,
            receiptNumber: receipt.receipt_no,
            total: receipt.total,
            createdAt: receipt.createdAt,
            printType: convertPrintTypeFromNumber(Number(receipt.print_type)),
            previewId: receipt.preview_id,
          };
        });
        dispatch(setReceiptList(receiptListTemp));
        dispatch(setReceiptTotalPage(res.total_page));
        dispatch(setReceiptLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setReceiptLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setReceiptLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};
