import { E_FETCH_STATUS } from "../../constant";
import { ReceiptFilterInterface, ReceiptList } from "../../interfaces/receipt";
import {
  ReceiptAction,
  ReceiptSetCurrentPageNumber,
  ReceiptSetFilter,
  ReceiptSetList,
  ReceiptSetLoading,
  ReceiptSetPageNumber,
  ReceiptType,
} from "../types/receipt";

interface ReceiptState {
  filter: ReceiptFilterInterface;
  receiptList: ReceiptList[];
  currentPage: number;
  totalPage: number;
  isLoading: E_FETCH_STATUS;
}

const initialState: ReceiptState = {
  filter: {
    filterReceiptNumber: "",
    filterCreatedAt: "",
  },
  receiptList: [],
  currentPage: 1,
  totalPage: 0,
  isLoading: E_FETCH_STATUS.INITIATE,
};

const {
  RECEIPT_SET_FILTER,
  RECEIPT_SET_CURRENT_PAGE,
  RECEIPT_SET_LIST,
  RECEIPT_SET_LOADING,
  RECEIPT_SET_PAGE_NUMBER,
} = ReceiptType;

const receipt = (
  state: ReceiptState = initialState,
  action: ReceiptAction
): ReceiptState => {
  const { type } = action;

  const setFilter = (): ReceiptState => {
    const { payload: filter } = action as ReceiptSetFilter;
    return {
      ...state,
      filter,
    };
  };

  const setIsLoading = (): ReceiptState => {
    const { payload: isLoading } = action as ReceiptSetLoading;
    return {
      ...state,
      isLoading,
    };
  };

  const setPageNumber = (): ReceiptState => {
    const { payload: totalPage } = action as ReceiptSetPageNumber;
    return {
      ...state,
      totalPage,
    };
  };

  const setCurrentPage = (): ReceiptState => {
    const { payload: currentPage } = action as ReceiptSetCurrentPageNumber;
    return {
      ...state,
      currentPage,
    };
  };

  const setReceiptList = (): ReceiptState => {
    const { payload: receiptList } = action as ReceiptSetList;
    return {
      ...state,
      receiptList,
    };
  };

  switch (type) {
    case RECEIPT_SET_FILTER:
      return setFilter();
    case RECEIPT_SET_LOADING:
      return setIsLoading();
    case RECEIPT_SET_PAGE_NUMBER:
      return setPageNumber();
    case RECEIPT_SET_CURRENT_PAGE:
      return setCurrentPage();
    case RECEIPT_SET_LIST:
      return setReceiptList();
    default:
      return state;
  }
};

export default receipt;
