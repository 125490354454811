import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS, ITEM_TYPE } from "../../constant";
import {
  InvoiceDataOdooResponse,
  InvoiceFilterInterface,
  InvoiceList,
  InvoiceListResponse,
} from "../../interfaces/invoice";
import InvoiceService from "../../services/Invoice";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { InvoiceAction, InvoiceType } from "../types/invoice";
import convertItemTypeFromNumber from "../../utils/convertItemTypeFromNumber";
import concatAddress from "../../utils/concatAddress";

const convertItemTypeToNumber = (itemNumber: ITEM_TYPE) => {
  switch (itemNumber) {
    case ITEM_TYPE.BLANCO:
      return 1;
    case ITEM_TYPE.HANSGROHE:
      return 2;
    case ITEM_TYPE.CGS:
      return 3;
    default:
      return 0;
  }
};

export const setInvoiceFilter = (
  filter: InvoiceFilterInterface
): InvoiceAction => {
  return {
    type: InvoiceType.INVOICE_SET_FILTER,
    payload: filter,
  };
};

export const setInvoiceLoading = (status: E_FETCH_STATUS) => {
  return {
    type: InvoiceType.INVOICE_SET_LOADING,
    payload: status,
  };
};

export const setInvoiceList = (invoiceList: InvoiceList[]) => {
  return {
    type: InvoiceType.INVOICE_SET_LIST,
    payload: invoiceList,
  };
};

export const setInvoiceTotalPage = (totalPage: number) => {
  return {
    type: InvoiceType.INVOICE_SET_PAGE_NUMBER,
    payload: totalPage,
  };
};

export const setCurrentPage = (page: number) => {
  return {
    type: InvoiceType.INVOICE_SET_CURRENT_PAGE_NUMBER,
    payload: page,
  };
};

export const loadInvoiceList = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setInvoiceLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        invoice: { filter, currentPage },
      } = reduxState;

      let params = `?page_number=${currentPage}`;
      if (filter.filterInvoiceNumber !== "") {
        params += `&invoice_no=${filter.filterInvoiceNumber}`;
      }
      if (filter.filterCustomerName !== "") {
        params += `&customer_name=${filter.filterCustomerName}`;
      }
      if (filter.filterTaxInvoiceNumber !== "") {
        params += `&tax_invoice_no=${filter.filterTaxInvoiceNumber}`;
      }
      if (filter.filterCustomerName !== "") {
        params += `&customer_name=${filter.filterCustomerName}`;
      }
      if (filter.filterItemType !== ITEM_TYPE.INITIATE) {
        params += `&item_type=${convertItemTypeToNumber(
          filter.filterItemType
        )}`;
      }
      if (filter.filterPONumber !== "") {
        params += `&po_no=${filter.filterPONumber}`;
      }
      const res: InvoiceListResponse = await InvoiceService.getList(params);
      if (res.data) {
        const invoiceListTemp: InvoiceList[] = res.data.map((invoice) => {
          return {
            id: invoice.id,
            invoiceNumber: invoice.invoice_no,
            customerName: invoice.customer_name,
            taxInvoiceNumber: invoice.tax_invoice_no,
            itemType: convertItemTypeFromNumber(
              Number(invoice.item_type)
            ) as ITEM_TYPE,
            address: concatAddress(invoice.address, invoice.address_2),
            PONumber: invoice.po_no,
            total: invoice.total,
            date: invoice.date,
          };
        });
        dispatch(setInvoiceList(invoiceListTemp));
        dispatch(setInvoiceTotalPage(res.total_page));
        dispatch(setInvoiceLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setInvoiceLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setInvoiceLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

export const getOdooData = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setInvoiceLoading(E_FETCH_STATUS.FETCHING));
      const res: InvoiceDataOdooResponse = await InvoiceService.fetchOdooData();
      if (res.status === 1) {
        dispatch(setInvoiceLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setInvoiceLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setInvoiceLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};
